export const sentenceSuggestion = [
	"Skipping breakfast will not help you lose weight.",
	"You could miss out on essential nutrients and you may end up snacking more throughout the day because you feel hungry.",
	"Eating at regular times during the day helps burn calories at a faster rate.",
	"It also reduces the temptation to snack on foods high in fat and sugar.",
	"Fruit and veg are low in calories and fat, and high in fibre – 3 essential ingredients for successful weight loss.",
	"They also contain plenty of vitamins and minerals.",
	"Being active is key to losing weight and keeping it off.",
	"As well as providing lots of health benefits, exercise can help burn off the excess calories you cannot lose through diet alone.",
	"Find an activity you enjoy and are able to fit into your routine.",
	"People sometimes confuse thirst with hunger.",
	"You can end up consuming extra calories when a glass of water is really what you need.",
	"Foods containing lots of fibre can help keep you feeling full, which is perfect for losing weight.",
	"Fibre is only found in food from plants, such as fruit and veg, oats, wholegrain bread, brown rice and pasta, and beans, peas and lentils.",
	"Knowing how to read food labels can help you choose healthier options.",
	"Use the calorie information to work out how a particular food fits into your daily calorie allowance on the weight loss plan.",
	"Using smaller plates can help you eat smaller portions.",
	"By using smaller plates and bowls, you may be able to gradually get used to eating smaller portions without going hungry.",
	"It takes about 20 minutes for the stomach to tell the brain it's full, so eat slowly and stop eating before you feel full.",
	"Do not ban any foods from your weight loss plan, especially the ones you like.",
	"Banning foods will only make you crave them more.",
	"There's no reason you cannot enjoy the occasional treat as long as you stay within your daily calorie allowance.",
	"To avoid temptation, do not stock junk food – such as chocolate, biscuits, crisps and sweet fizzy drinks – at home.",
	"Instead, opt for healthy snacks, such as fruit, unsalted rice cakes, oat cakes, unsalted or unsweetened popcorn, and fruit juice.",
	"A standard glass of wine can contain as many calories as a piece of chocolate.",
	"Over time, drinking too much can easily contribute to weight gain.",
	"Try to plan your breakfast, lunch, dinner and snacks for the week, making sure you stick to your calorie allowance.",
	"You may find it helpful to make a weekly shopping list.",
	"To lose weight, you need to burn more calories than you consume.",
	"This can be achieved by eating less, moving more or, best of all, a combination of both.",
	"For example, choosing cheaper cuts of meat and mixing it with cheaper alternatives such as beans, pulses and frozen veg will make it go further in casseroles or stir-fries.",
	"Eat whole grain and wholemeal carbohydrates such as brown rice and wholemeal bread, and potatoes with the skins on to increase your intake of fibre and don't fry starchy foods when trying to lose weight.",
	"You may also be missing out on essential nutrients as crash diets can be limited in the variety of food consumed.",
	"Your body will be low on energy, and may cause you to crave high-fat and high-sugar foods.",
	"This can lead to eating those foods and more calories than you need, causing weight gain.",
	"Metabolism describes all the chemical processes that go on continuously inside the body to keep you alive and your organs functioning normally, such as breathing, repairing cells and digesting food.",
	"These processes need energy and the amount of energy required varies between individuals depending on factors such as body size, age, gender and genes.",
	'Foods labelled "low fat" have to contain no more than a specific amount of fat to legally use that label.',
	'If a food is labelled as "low-fat" or "reduced fat", it should contain less fat than the full-fat version, but that doesn\'t automatically make it a healthy choice: Check the label to see how much fat it contains.',
	"Some low-fat foods may also contain high levels of sugar.",
	"Many people need a snack in-between meals to maintain energy levels, especially if they have an active lifestyle.",
	"Choose fruit or vegetables instead of crisps, chocolate and other snacks that are high in sugar, salt and fat.",
	"Water does not cause you to lose weight, but it does keep you hydrated and might help you snack less.",
	"Water is essential for good health and wellbeing.",
	"Sometimes thirst can be mistaken for hunger – if you're thirsty you may snack more.",
	"Skipping meals is not a good idea.",
	"To lose weight and keep it off, you have to reduce the amount of calories you consume and increase the calories you burn through exercise.",
	"But skipping meals altogether can result in tiredness and may mean you miss out on essential nutrients.",
	"You will also be more likely to snack on high-fat and high-sugar foods, which could result in weight gain.",
];
